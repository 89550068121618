body {
    margin: 0;
    max-width: 8.5in;
    font-family: "Open Sans", "Avenir Next", "Arial", sans-serif;
    line-height: 1.5;
}

[class*=" icon-"],
[class^=icon-] {
    font-family: "Vedder Price";
    line-height: 1;
}

.icon-twitter::after {
    content: "\e902";
}

.print-only { display: block; }

.tablet-only,
.mobile-only,
.no-print,
.visually-hidden {
    display: none;
}

.site-header-drawer,
.site-header__tools {
    display: none;
}

.site-header__logo img {
    width: 350px;
    height: auto;
}

.standard-panel-nav {
    display: none;
}

.standard-panel {
    .standard-panel__decoration-container,
    .standard-panel__navigation-container,
    .standard-panel__service-landing-decoration-container,
    .detail-tabs-nav {
        display: none;
    }
    .standard-panel__content-container {
        .standard-panel__introduction-highlights-container ul li a {
            display: block;
        }
        .standard-search .standard-search-form,
        .standard-search-form--services-landing,
        .services-landing-content__view-button,
        .services-grid__view-all-button {
            display: none;
        }
    }
    .detail-tabs {
        .detail-tab {
            clear: left;
            .detail-tab__generic-container>ul {
                padding-left: 0;
            }
        }
    }
    .standard-search-results--site-search {
        .standard-search-results__filters {
            display: none;
        }
        .standard-search-results {
            img {
                display: none;
            }
            .gss-result-container:nth-child(n + 1) {
                margin-bottom: 1em;
            }            
        }
    }
    .standard-search-results--professionals-landing {
        .standard-search-results__results {
            table {
                thead td {
                    font-weight: bold;
                    &.standard-search-results__header-email {
                        display: none;
                    }
                }
                tbody td {
                    vertical-align: top;
                    white-space: nowrap;
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none none;
                    }
                    &.standard-search-results__result-email {
                        display: none;
                    }
                }
            }
        }
    }
    .load-more-form__submit-button {
        display: none;
    }
    &.standard-panel--professionals-detail {
        .professionals-detail-tab__social-list {
            display: none;
        }
        .detail-tab {
            .professionals-detail-tab__name {
                display: none;
            }
            &.detail-tab--is-selected {
                .professionals-detail-tab__name {
                    display: block;
                }
            }
        }
    }
    &.standard-panel--offices-landing {
        clear: left;
        .standard-panel__content-container {
            .standard-panel__introduction-highlights-container {
                .fly-in {
                    float: left;
                    width: 30%;
                }
            }
            a.standard-panel__more-anchor {
                display: none;
            }
        }
    }
    &.standard-panel--vedder-thinking-detail {
        .detail-tabs {
            #services { display: none; }
        }
    }
    .professionals-card-grid__flex-container {
        .professionals-card {
            float: left;
            width: 33%;
            h3,
            .professionals-card__title,
            .professionals-card__email {
                margin: 0;
            }
            .professionals-card__icon-list {
                display: none;
            }
            .professionals-card__phone {
                margin-top: 0;
            }
            
            .professionals-card__image {
                max-width: 174px;
            }
            .professionals-card__email {
                max-width: 174px;
                word-wrap: break-word;
            }
        }
    }
}

.vedder-thinking-header img,
.vedder-thinking-nav {
    display: none;
}

.vedder-thinking-grid {
    ul {
        list-style: none none;
        margin: 0;
        padding: 0;
        li {
            &:nth-child(n + 1) {
                margin-bottom: 1em;
            }
            .vedder-thinking-grid__tag {
                font-weight: bold;
            }
            h2 {
                font-size: 1em;
                margin: 0;
            }
            p:nth-child(n + 1) {
                margin: 0;
            }
            .featured-image-primary {
                display: none;
            }
        }
    }
}

.vedder-thinking-tab {

    img {
        display: none;
    }

    .block-with-text {
        max-height: inherit !important;
    }

    .twitter-tweets {
        background-color: #dcdcdc;
        border-radius: 2px;
        padding: 10px;
    }
    .load-more-form__submit-button {
        display: none;
    }
}

.vedder-thinking-twitter-feed {
    background-color: #f4f4f4;
    padding: 1rem;
    $color-twitter-blue: #1da1f3;
    $color-twitter-gray: #8e8e8e;
    .twitter-icon-container {
        float: left;
        padding-right: 20px;
        .icon-twitter {
            color: $color-twitter-blue;
            font-size: 3rem;
        }
    }
    .twitter-screenname-container {
        margin-bottom: 8px;
        .twitter-screenname {
            color: $color-twitter-gray;
            &:hover {
                color: shade($color-twitter-gray);
            }
        }
    }
    .twitter-content-container {
        clear: both;
        padding-top: 10px;
        .twitter-content {
            color: $color-twitter-blue;
        }
    }
    .twitter-date {
        color: $color-twitter-gray;
    }
    .twitter-nav {
        display: none;
    }
}

.back-to-top__anchor {
    display: none;
}

.site-footer {
    clear: both;
    .site-footer__social-icon-list {
        display: none;
    }
    .site-footer__site-link-list {
        display: none;
    }
}

.header-banner {
    margin-bottom: 40px;
}

.header-banner__title {
    margin-bottom: 2px;
}
.header-banner__date {
    display: block
}


.professional-grid__item {
    margin-bottom: 20pt
}

.professional-grid__link {
    display: none;
}