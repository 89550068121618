body {
  margin: 0;
  max-width: 8.5in;
  font-family: "Open Sans", "Avenir Next", "Arial", sans-serif;
  line-height: 1.5; }

[class*=" icon-"],
[class^=icon-] {
  font-family: "Vedder Price";
  line-height: 1; }

.icon-twitter::after {
  content: "\e902"; }

.print-only {
  display: block; }

.tablet-only,
.mobile-only,
.no-print,
.visually-hidden {
  display: none; }

.site-header-drawer,
.site-header__tools {
  display: none; }

.site-header__logo img {
  width: 350px;
  height: auto; }

.standard-panel-nav {
  display: none; }

.standard-panel .standard-panel__decoration-container,
.standard-panel .standard-panel__navigation-container,
.standard-panel .standard-panel__service-landing-decoration-container,
.standard-panel .detail-tabs-nav {
  display: none; }

.standard-panel .standard-panel__content-container .standard-panel__introduction-highlights-container ul li a {
  display: block; }

.standard-panel .standard-panel__content-container .standard-search .standard-search-form,
.standard-panel .standard-panel__content-container .standard-search-form--services-landing,
.standard-panel .standard-panel__content-container .services-landing-content__view-button,
.standard-panel .standard-panel__content-container .services-grid__view-all-button {
  display: none; }

.standard-panel .detail-tabs .detail-tab {
  clear: left; }
  .standard-panel .detail-tabs .detail-tab .detail-tab__generic-container > ul {
    padding-left: 0; }

.standard-panel .standard-search-results--site-search .standard-search-results__filters {
  display: none; }

.standard-panel .standard-search-results--site-search .standard-search-results img {
  display: none; }

.standard-panel .standard-search-results--site-search .standard-search-results .gss-result-container:nth-child(n + 1) {
  margin-bottom: 1em; }

.standard-panel .standard-search-results--professionals-landing .standard-search-results__results table thead td {
  font-weight: bold; }
  .standard-panel .standard-search-results--professionals-landing .standard-search-results__results table thead td.standard-search-results__header-email {
    display: none; }

.standard-panel .standard-search-results--professionals-landing .standard-search-results__results table tbody td {
  vertical-align: top;
  white-space: nowrap; }
  .standard-panel .standard-search-results--professionals-landing .standard-search-results__results table tbody td ul {
    margin: 0;
    padding: 0;
    list-style: none none; }
  .standard-panel .standard-search-results--professionals-landing .standard-search-results__results table tbody td.standard-search-results__result-email {
    display: none; }

.standard-panel .load-more-form__submit-button {
  display: none; }

.standard-panel.standard-panel--professionals-detail .professionals-detail-tab__social-list {
  display: none; }

.standard-panel.standard-panel--professionals-detail .detail-tab .professionals-detail-tab__name {
  display: none; }

.standard-panel.standard-panel--professionals-detail .detail-tab.detail-tab--is-selected .professionals-detail-tab__name {
  display: block; }

.standard-panel.standard-panel--offices-landing {
  clear: left; }
  .standard-panel.standard-panel--offices-landing .standard-panel__content-container .standard-panel__introduction-highlights-container .fly-in {
    float: left;
    width: 30%; }
  .standard-panel.standard-panel--offices-landing .standard-panel__content-container a.standard-panel__more-anchor {
    display: none; }

.standard-panel.standard-panel--vedder-thinking-detail .detail-tabs #services {
  display: none; }

.standard-panel .professionals-card-grid__flex-container .professionals-card {
  float: left;
  width: 33%; }
  .standard-panel .professionals-card-grid__flex-container .professionals-card h3,
  .standard-panel .professionals-card-grid__flex-container .professionals-card .professionals-card__title,
  .standard-panel .professionals-card-grid__flex-container .professionals-card .professionals-card__email {
    margin: 0; }
  .standard-panel .professionals-card-grid__flex-container .professionals-card .professionals-card__icon-list {
    display: none; }
  .standard-panel .professionals-card-grid__flex-container .professionals-card .professionals-card__phone {
    margin-top: 0; }
  .standard-panel .professionals-card-grid__flex-container .professionals-card .professionals-card__image {
    max-width: 174px; }
  .standard-panel .professionals-card-grid__flex-container .professionals-card .professionals-card__email {
    max-width: 174px;
    word-wrap: break-word; }

.vedder-thinking-header img,
.vedder-thinking-nav {
  display: none; }

.vedder-thinking-grid ul {
  list-style: none none;
  margin: 0;
  padding: 0; }
  .vedder-thinking-grid ul li:nth-child(n + 1) {
    margin-bottom: 1em; }
  .vedder-thinking-grid ul li .vedder-thinking-grid__tag {
    font-weight: bold; }
  .vedder-thinking-grid ul li h2 {
    font-size: 1em;
    margin: 0; }
  .vedder-thinking-grid ul li p:nth-child(n + 1) {
    margin: 0; }
  .vedder-thinking-grid ul li .featured-image-primary {
    display: none; }

.vedder-thinking-tab img {
  display: none; }

.vedder-thinking-tab .block-with-text {
  max-height: inherit !important; }

.vedder-thinking-tab .twitter-tweets {
  background-color: #dcdcdc;
  border-radius: 2px;
  padding: 10px; }

.vedder-thinking-tab .load-more-form__submit-button {
  display: none; }

.vedder-thinking-twitter-feed {
  background-color: #f4f4f4;
  padding: 1rem; }
  .vedder-thinking-twitter-feed .twitter-icon-container {
    float: left;
    padding-right: 20px; }
    .vedder-thinking-twitter-feed .twitter-icon-container .icon-twitter {
      color: #1da1f3;
      font-size: 3rem; }
  .vedder-thinking-twitter-feed .twitter-screenname-container {
    margin-bottom: 8px; }
    .vedder-thinking-twitter-feed .twitter-screenname-container .twitter-screenname {
      color: #8e8e8e; }
      .vedder-thinking-twitter-feed .twitter-screenname-container .twitter-screenname:hover {
        color: shade(#8e8e8e); }
  .vedder-thinking-twitter-feed .twitter-content-container {
    clear: both;
    padding-top: 10px; }
    .vedder-thinking-twitter-feed .twitter-content-container .twitter-content {
      color: #1da1f3; }
  .vedder-thinking-twitter-feed .twitter-date {
    color: #8e8e8e; }
  .vedder-thinking-twitter-feed .twitter-nav {
    display: none; }

.back-to-top__anchor {
  display: none; }

.site-footer {
  clear: both; }
  .site-footer .site-footer__social-icon-list {
    display: none; }
  .site-footer .site-footer__site-link-list {
    display: none; }

.header-banner {
  margin-bottom: 40px; }

.header-banner__title {
  margin-bottom: 2px; }

.header-banner__date {
  display: block; }

.professional-grid__item {
  margin-bottom: 20pt; }

.professional-grid__link {
  display: none; }
